.assign-topic-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 250px;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      svg {
        cursor: pointer;
      }
    }
    .select-agent {
      padding: 15px;
      overflow: auto;
      height: 240px;
      display: flex;
      flex-direction: column;
      .agent {
        display: flex;
        padding: 10px;
        align-items: center;
        border-bottom: 1px solid #0dc1ce;
        p {
          margin-left: 10px;
        }
      }
    }
  }
