.date-picker {
  height: 40px;
  border-radius: 7px;
  border: 1px solid rgb(165, 164, 164);
  width: 100%;
  font-size:1.1rem;
  display: flex;
  align-items: center;
}
.date-picker:focus {
  outline: none;
}
.date-icon {
  background-image: url("/chat-actions-icons/calendar.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 1%;
}
.react-datepicker-wrapper{
  max-height: 40px;
  &.failed{
    border:1px solid red;
    .date-picker{
      border:none;
    }
  }
}