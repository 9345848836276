.heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.user-info-container {
  display: flex;
  align-items: center;
  .options-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .options-right {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    .textfield {
      width: 500px;
      margin: 10px 0;
    }
    .password-field-container {
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
  }
}


.systems-list::-webkit-scrollbar{
  width:5px;
  border:none;
}

.systems-list::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}

.systems-list::-webkit-scrollbar-thumb {
  background-color: rgb(196, 204, 202);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(238, 241, 241);  /* creates padding around scroll thumb */
}