.all-users-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .system-heading {
      font-size: 22px;
      margin-top: 20px;
    }
  }
  .system-content {
    margin-top: 50px;
    .filters-container {
      display: flex;
      align-items: center;
      .filter-button {
        text-transform: none;
        color: black;
      }
    }
  }
}

::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px lightgray;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 2px;
}
