@import "../../base.scss";

.chat-card-container {
  max-height: 170px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  cursor: pointer;
  .divider {
    background-color: black;
    height: 25px;
    margin: 0 8px;
  }
  .card-heading {
    display: flex;
    justify-content: space-between;
  }
  .card-header {
    font-family: $font-family-Xbold;
    font-size: 17px;
    white-space: nowrap;
  }
  .card-heading-left,
  .card-heading-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-info {
    display: flex;
    font-size: 15px;
    align-items: center;
    margin-top: 1%;
    .dateInfo {
      font-size: 15px;
      white-space: nowrap;
    }
    .responsible {
      font-size: 15px;
      white-space: nowrap;
      display: flex;
    }
    .chat-text {
      width: 90%;
    }
    .notification-number {
      padding-top: 6px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: #0dc1ce;
      color: white;
      border: 1px solid #0dc1ce;
      text-align: center;
    }
  }
}

.special-user {
  background-image: url("/star.svg");
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 900px) {
  .chat-card-container {
    max-height: 400px;
    .card-header {
      white-space: unset !important;
    }
    .card-info{
      display: block !important;
      margin-top:10px;
    }
    .divider {
      height: 1px;
      margin:10px 0px;
    }
  }
 
}
