@font-face {
  font-family: "Lexis-Black-Italic";
  src: url("fonts/Typedepot - Lexis Black Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Black";
  src: url("fonts/Typedepot - Lexis Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Bold-Italic";
  src: url("fonts/Typedepot - Lexis Bold Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Bold";
  src: url("fonts/Typedepot - Lexis Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Book-Italic";
  src: url("fonts/Typedepot - Lexis Book Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Book";
  src: url("fonts/Typedepot - Lexis Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Light-Italic";
  src: url("fonts/Typedepot - Lexis Light Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Light";
  src: url("fonts/Typedepot - Lexis Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Medium";
  src: url("fonts/Typedepot - Lexis Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Medium-Italic";
  src: url("fonts/Typedepot - Lexis Medium Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Thin-Italic";
  src: url("fonts/Typedepot - Lexis Thin Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-Thin";
  src: url("fonts/Typedepot - Lexis Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-XBold-Italic";
  src: url("fonts/Typedepot - Lexis XBold Italic_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lexis-XBold";
  src: url("fonts/Typedepot - Lexis XBold_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
// @font-face {
//   font-family:'CorsaGrotesk-Thin';
//   src:url('fonts/CorsaGrotesk-Thin.eot');
//   src:url('fonts/CorsaGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
//        url('fonts/CorsaGrotesk-Thin.svg#CorsaGrotesk-Thin') format('svg'),
//        url('fonts/CorsaGrotesk-Thin.ttf') format('truetype'),
//        url('fonts/CorsaGrotesk-Thin.woff') format('woff'),
//        url('fonts/CorsaGrotesk-Thin.woff2') format('woff2');
//   font-weight:normal;
//   font-style:normal;
// }
// @font-face {
//   font-family:'CorsaGrotesk-Light';
//   src:url('fonts/CorsaGrotesk-Light.eot');
//   src:url('fonts/CorsaGrotesk-Light.eot?#iefix') format('embedded-opentype'),
//        url('fonts/CorsaGrotesk-Light.svg#CorsaGrotesk-Light') format('svg'),
//        url('fonts/CorsaGrotesk-Light.ttf') format('truetype'),
//        url('fonts/CorsaGrotesk-Light.woff') format('woff'),
//        url('fonts/CorsaGrotesk-Light.woff2') format('woff2');
//   font-weight:normal;
//   font-style:normal;
// }
// @font-face {
//   font-family:'CorsaGrotesk-Bold';
//   src:url('fonts/CorsaGrotesk-Bold.eot');
//   src:url('fonts/CorsaGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
//        url('fonts/CorsaGrotesk-Bold.svg#CorsaGrotesk-Bold') format('svg'),
//        url('fonts/CorsaGrotesk-Bold.ttf') format('truetype'),
//        url('fonts/CorsaGrotesk-Bold.woff') format('woff'),
//        url('fonts/CorsaGrotesk-Bold.woff2') format('woff2');
//   font-weight:normal;
//   font-style:normal;
// }
// @font-face {
//   font-family:'CorsaGrotesk-Black';
//   src:url('fonts/CorsaGrotesk-Black.eot');
//   src:url('fonts/CorsaGrotesk-Black.eot?#iefix') format('embedded-opentype'),
//        url('fonts/CorsaGrotesk-Black.svg#CorsaGrotesk-Black') format('svg'),
//        url('fonts/CorsaGrotesk-Black.ttf') format('truetype'),
//        url('fonts/CorsaGrotesk-Black.woff') format('woff'),
//        url('fonts/CorsaGrotesk-Black.woff2') format('woff2');
//   font-weight:normal;
//   font-style:normal;
// }

// @font-face {
//   font-family: 'icomoon';
//   src:  url('fonts/icomoon.eot?qtvcbr');
//   src:  url('fonts/icomoon.eot?qtvcbr#iefix') format('embedded-opentype'),
//     url('fonts/icomoon.ttf?qtvcbr') format('truetype'),
//     url('fonts/icomoon.woff?qtvcbr') format('woff'),
//     url('fonts/icomoon.svg?qtvcbr#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
