@import "../../../base.scss";

.assign-topic-modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 800px;
  .assign-topic-heading-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}
.labels-container {
  width: 100%;
  padding: 1vw;
  height: 88%;
}
.label {
  width: 100%;
  height: 40px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  border-radius: 7px;
  margin-top: 2%;
}
.edit-group {
  background-image: url("/chat-actions-icons/edit-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12%;
  height: 40px;
  margin-left: 2%;
  margin-right: 5%;
  cursor: pointer;
}
.delete-group {
  background-image: url("/chat-actions-icons/delete.svg") !important;
}
.empty-template{
  background-image: url("/chat-actions-icons/labels-empty.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 50%;
  width: 50%;
  margin-top:2%;
}