.form-container {
  margin: 20px 0;
  min-height: 320px;;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .heading-container {
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
  .form-inputs {
    display: flex;
    align-items: flex-start;
    width:100%;
    .textfield {
      width: 90%;
      margin-top:2%;
    }
  }
  .form-checkboxes {
    flex-direction: row;
  }
}

.outer-checkbox{
  width:20px;
  height:20px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-right:5px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.inner-checkbox{
  width:10px;
  height:10px;
  border-radius: 2px;
  background-color: #0DC1CE;
  display: none;
}
.show{
  display: block;
}