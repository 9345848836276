.system-card-container {
  width: 100%;
  max-height: 74px;
  margin-bottom: 10px;
  font-weight: bold;
  .card-content {
    display: flex;
    justify-content: space-between;
    cursor:pointer;
    .card-info {
      align-items: center;
      display: flex;
      justify-content: space-between;
      hr {
        margin: 0 8px;
      }
    }
  }
}

.mobile-arrow{
  background-image: url("/mobile-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}