@import "../../../base.scss";

.side-tools-container {
  height: 89vh;
  width: 28%;
  margin: 20px 10px;
  padding: 10px;
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .users-info-container,
  .created-topics-container,
  .opened-topics-container,
  .topics-done-container,
  .no-ratings-container {
    cursor: pointer;
    margin-top: 15px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width:100%;
  }
  .users-info-container {
    background-color: #e2f6ff;
    .users-icon {
      background-color: #2bbafa;
      width:40px;
      height:40px;
      background-image: url("/helpcenter-icons/users.svg");
    }
  }
  .created-topics-container {
    background-color: white;
    width:100%;
    justify-content: flex-start;
    border-radius: 9px;
    .created-topics-icon {
      background-color: #8244eb;
      width:40px;
      height:40px;
      background-image: url("/helpcenter-icons/all-chats.svg");
    }
  }

  .gray{
    background-color: #f2f3ff;
  }

  .selector{
    background-image: url("/select-icon.svg");
    width: 40px;
    height: 32px;
    background-repeat: no-repeat;
  }
  .opened-topics-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: #effbfc;
    .options {
      display: flex;
      align-items: center;
      h4 {
        padding: 20px;
      }
    }
    .opened-topics-icon {
      background-color: #0dc1ce;
      background-image: url("/helpcenter-icons/active-chats.svg");
    }
  }
  .topics-done-container {
    background-color: #edfff9;
    display: flex;
    justify-content: flex-start;
    .options {
      display: flex;
      align-items: center;
      h4 {
        padding: 20px;
      }
    }
    .topics-done-icon {
      background-color: white;
      width:40px;
      height:40px;
      background-image: url("/helpcenter-icons/with-rating.svg");
    }
  }
  .more-options {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height:50px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top:2%;
    margin-bottom:1%;
    margin-left:1%;
    .options {
      display: flex;
      align-items: center;
    }
    
  }
  .ratings-container {
    cursor: pointer;
    padding: 10px 10px 10px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rating {
      position: relative;
      margin-right: 10px;
      line-height: 2.8em;
      text-align: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: white;
    }
  }
  
  .no-ratings-container{
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    .options {
      display: flex;
      align-items: center;
      h4 {
        padding: 20px;
      }
    }
    .no-ratings-icon {
      background-color: #01d088;
      width:40px;
      height:40px;
      background-image: url("/helpcenter-icons/without.svg");
    }
  }

}

.no-ratings-container{
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  .options {
    display: flex;
    align-items: center;
    h4 {
      padding: 20px;
    }
  }
  .topics-done-icon {
    background-color: white;
    width:40px;
    height:40px;
    background-image: url("/helpcenter-icons/with-rating.svg");
  }
}

.users-number{
  font-size:20px;
  font-family: $font-family-Xbold;
  margin-left:2%;
}
.paper-header{
  font-size:16px;
  white-space: nowrap;
  margin-left:2%;
  display:flex;
  align-items: center;
}
.notification {
  margin-right: 10px;
  line-height: 2.7em;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.opened-topics-icon{
  background-image: url("/opened.svg");
  width:40px;
  height:40px;
}
.completed-topics-icon{
  background-image: url("/completed.svg");
  width:40px;
  height:40px;
}
.archived-topics-icon{
  background-image: url("/archived.svg");
  width:40px;
  height:40px;
}
.archived-topics-icon{
  background-image: url("/archived.svg");
  width:40px;
  height:40px;
}
.archived-topics-icon{
  background-image: url("/archived.svg");
  width:40px;
  height:40px;
}
.deleted-topics-icon{
  background-image: url("/helpcenter-icons/delete.svg");
  width:42px;
  height:40px;
  color:black;
}

@media screen and (max-width: 1600px){
  .users-number{
    font-size:18px;
  }
  .paper-header{
    font-size:13px;
  }
}
@media screen and (max-width: 1500px){
  .users-number{
    font-size:16px;
  }
  .paper-header{
    font-size:12px;
  }
}
@media screen and (max-width: 1400px){
  .users-number{
    font-size:16px;
  }
  .paper-header{
    font-size:12px;
  }
}
@media screen and (max-width: 1300px){
  .users-number{
    font-size:15px;
  }
  .paper-header{
    font-size:11px;
  }
}