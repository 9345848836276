.header-toolbar {
  justify-content: space-between;
  a {
    padding: 25px;
    -webkit-appearance: none;
    &.active {
      -webkit-appearance: none;
      font-weight: bold;
      background-color: rgba(13, 193, 206, 0.3);
      border-bottom: 0.5px solid rgba(13, 193, 206, 1);
    }
  }
}
.user-menu {
  li {
    border-top: 1px solid #0dc1ce;
    margin: 5px 20px;
    svg {
      margin-right: 10px;
    }
  }
  .menu-options {
    display: flex;
    padding: 14px;
    justify-content: space-between;
    align-items: center;
  }
  .user-info {
    margin: 12px 30px;
    display: flex;
    align-items: center;
    div {
      margin-right: 15px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .header-toolbar{
    width: 95vw;
  }
 
}