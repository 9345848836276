.assign-topic-modal {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 50%;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
  }