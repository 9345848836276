.heading-container {
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
}
.edit-password-form {
  padding: 20px;
  height: 100%;
  justify-content: center;
  .FormControl {
    margin-top: 30px;
  }
  .submit-button {
    margin-top: 40px;
    background-color: #01d088;
    color: white;
  }
}
