.discount-info-modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 800px;
  .discount-info-heading-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
}

.activity-window {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-left:2%;
  .activity {
    margin: 15px 0;
    padding-left: 10px;
    position: relative;
    border-left: 5px solid #0dc1ce;
    .avatar-container {
      display: flex;
      align-items: center;
      margin-top: 1%;
      margin-bottom: 1%;
    }
  }
  .activity::after {
    content: "";
    position: absolute;
    background-color: black;
    width: 1px;
    height: 30px;
    left: -0.5%;
  }
  .activity:last-of-type::after {
    display: none;
  }
}
