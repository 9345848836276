@import "./base.scss";

/* BASE */
body {
  color: $txt-color;
  font-size: $font-size;
  font-family: $font-family-regular;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
form,
section {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-header;
}
h1 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
h2 {
  padding-bottom: 20px;
  font-size: $font-size-xl;
  font-weight: normal;
}
a {
  color: $txt-color;
  text-decoration: none;
  @include transition($animation);
}

.pointer {
  cursor: pointer;
}

p:last-child {
  padding-bottom: 0;
}

ul li {
  list-style: none;
}
ol {
  list-style-position: inside;
}

b,
.bold,
.font-bold {
  font-family: $font-family-bold;
}
.font-medium {
  font-family: $font-family-medium;
}
.font-regular {
  font-family: $font-family-regular;
}
.font-black {
  font-family: $font-family-black;
}
.font-light {
  font-family: $font-family-light;
}
.font-thin {
  font-family: $font-family-thin;
}

.animation {
  @include transition($animation);
}
.txt-shadow {
  @include text-shadow($txt-shadow);
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: inline-block;
}

.txt-center {
  text-align: center;
}
.txt-left {
  text-align: left;
}
.txt-right {
  text-align: right;
}
.txt-justify {
  text-align: justify;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.align-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.align-space-around {
  display: flex;
  justify-content: space-around;
}
.align-space-between {
  display: flex;
  justify-content: space-between;
}

.css-1nmdiq5-menu {
  z-index: 100 !important;
}
#react-select-3-listbox {
  z-index: 100 !important;
}
.css-1n6sfyn-MenuList {
  max-height: 250px !important;
}

/*end BASE*/

/*LAYOUT*/
