.edit-profile-page-container {
  height: 88vh;
  margin: 30px 50px;
  .edit-profile-toolbar {
    display: flex;
    align-items: center;
    max-height: 60px;
    padding: 20px;
    justify-content: space-between;
  }
  .form-container {
    margin-top: 40px;
  }
}
