.amarant-container{
    height: 93vh;
    padding:1vw;
}
.upper-info-container{
    width:100%;
    height:7%;
    box-shadow: 0 1px 4px rgba(5, 5, 5, 0.2);
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
    .title{
        font-size:22px;
        font-weight: bold;
        padding-bottom: 0px;
        margin-left:1%;
    }
}
.middle-info-container{
    width:100%;
    height:24%;
    box-shadow: 0 1px 4px rgba(5, 5, 5, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-top:2%;
    padding:1vw;
    .title{
        font-size:22px;
        font-weight: bold;
        padding-bottom: 0px;
    }
    .main-parameters-container{
        width: 95%;
        margin-top:1%;
        display: flex;
        //justify-content: space-between;
    }
    &.block{
        display: block;
    }
}
.lower-info-container{
    width:100%;
    box-shadow: 0 1px 4px rgba(5, 5, 5, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-top:2%;
    padding:1vw;
    .title{
        font-size:22px;
        font-weight: bold;
        padding-bottom: 0px;
    }
    .main-parameters-container{
        width: 95%;
        margin-top:1%;
        display: flex;
        justify-content: space-between;
    }
    .main-parameters-container-files{
        width: 95%;
        margin-top:1%;
        display: flex;
    }
}
.delete-icon{
    background-image: url("/chat-actions-icons/delete.svg");
    background-size: contain;
    width: 20px;
    height: 20px;
    margin-left:1.5%;
    cursor: pointer;
}

.css-3w2yfm-ValueContainer{
    overflow-y:auto !important;
}
.info-icon{
    background-image: url("/chat-actions-icons/info.svg");
    background-size: contain;
    width: 17px;
    height: 17px;
    margin-left:1%;
    cursor: pointer;
    background-repeat: no-repeat;
    &.verified{
        width:30px;
        margin-left:4%;
    }
}