.ratings-container {
  background-color: rgba(217, 217, 217, 0.5);
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;

  .rating {
    padding: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .ratings-container{
    font-size:11px;
  }
}