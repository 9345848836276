.chat-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-around;
  .chat-header {
    padding: 10px;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    height: 45px;
    // width: 100%;
    .current-assignee {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 20px;
      }
    }
  }
  svg {
    cursor: pointer;
  }
  .messages-container {
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    .messages-history {
      height: 800px;
      overflow: auto;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
}
.form-container {
  display: flex;
  background-color: #effbfc;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
  width:100%;
}

.user-icon {
  background-image: url("/user.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  cursor: pointer;
  height: 30px;
}
.special-user {
  background-image: url("/star.svg");
  width: 15px;
  height: 15px;
  padding-right: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.unset {
  height: unset !important;
}
.reply-action-container {
  width: 100%;
  height: 50px;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reply-icon {
  background-image: url("/chat-actions-icons/reply-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  margin: 0px 5px;
}
.close-reply-action-icon {
  background-image: url("/chat-actions-icons/delete.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}
.form-paper-container {
  width: 100%;
}
