.assign-topic-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 470px;
  .assign-topic-heading-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
}
.current-assignee {
  display: flex;
  align-items: center;
  margin-left:5%;
  & > * {
    margin-right: 10px;
  }
}
.user-icon{
    background-image: url("/user.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width:25px;
    cursor: pointer;
    height:25px;
  }
@media only screen and (max-width: 900px) {
  .assign-topic-modal {
    width: 100%;
    height: 100%;
    .assign-topic-heading-container {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      svg {
        width: 1.3em;
        height: 1.3em;
      }
    }
  }
}
