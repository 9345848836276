.assign-topic-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 470px;
  .assign-topic-heading-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  .select-agent {
    padding: 15px;
    overflow: auto;
    height: 270px;
    display: flex;
    flex-direction: column;
    .agent {
      display: flex;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #0dc1ce;
      p {
        margin-left: 10px;
      }
    }
  }
}
.outer-checkbox{
  width:20px;
  height:20px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-right:5px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.inner-checkbox{
  width:10px;
  height:10px;
  border-radius: 2px;
  background-color: #0DC1CE;
  display: none;
}
.show{
  display: block;
}
@media only screen and (max-width: 900px) {
  .assign-topic-modal{
    width: 100%;
    height: 100%;
    .assign-topic-heading-container{
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      svg{
        width: 1.3em;
        height: 1.3em;
      }
    }
  }
}
