.amarant-container{
    height: 99%;
}
.upper-info-container{
    width:100%;
    height:5%;
    box-shadow: 0 1px 4px rgba(5, 5, 5, 0.2);
    font-size:14px;
    display: flex;
    align-items: center;
    border-radius:5px;
}
.lower-info-container{
    height:94%;
    padding:20px;
    width:100%;
    font-size:14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .empty-immotech-image{
    background-image: url("/illustration-immotech.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50%;
    height: 50%;
  }
}
.info-icon{
    background-image: url("/chat-actions-icons/info.svg");
    background-size: contain;
    width: 17px;
    height: 17px;
    margin-left:10%;
    cursor: pointer;
    background-repeat: no-repeat;
    &.verified{
        width:30px;
        margin-left:4%;
    }
}

@media screen and (max-width: 900px){
    .upper-info-container{
        height: unset;
        background-color: white;
        flex-direction: column;
        align-items: flex-start;
        padding:1vw;
        p{
            font-size:15px;
            margin-top:2%;
            margin-bottom:2%;
        }
    }
    .helper-cont{
        margin-top:2%;
        margin-bottom:2%;
        align-items: center;
    }
    .activity-modal{
        width:100% !important;
        height:99.5%;
    }
    .activity-heading-container{
        justify-content: flex-start;
    }
    .assign-topic-modal{
        width: 100%;
        height: 100%;
    }
    .assign-topic-heading-container{
        flex-direction: row-reverse;
        justify-content: flex-end !important;
        align-items: center;
    }
    .headerModal{
        justify-content: flex-end;
        flex-direction: row-reverse;
    }
    .closeModal{
        width: 1.3em;
        height: 1.3em;
    }
    .copyButton{
        width: 100%;
    }
    .lower-info-container{
        padding: 12px;
        height: 65%;
        
    }
  }