@import "../../base.scss";
// @import "reactjs-popup/dist/index.css";

.message {
  width: 100%;
  padding: 12px;
  padding-top: 0;
  margin: 5px 0px;
  &:last-child {
    padding-top: 12px;
  }
  &.me {
    text-align: right;
    .message-body {
      background-color: #f5f4f4;
    }
  }
  .sender {
    font-weight: 900;
    font-family: "Lexis-Bold", sans-serif;
    padding-bottom: 10px;
  }
  .time {
    display: inline-block;
    font-size: 14px;
    padding: 10px;
  }
  .message-body {
    white-space: pre-line;
    background-color: #f4fafa;
    font-weight: 400;
    display: inline-block;
    padding: 12px;
    border-radius: 10px;
    max-width: 750px;
    min-width: 150px;
    line-height: 20px;
    text-align: initial;
    overflow-wrap: break-word;
  }
  .media {
    color: #0dc1ce;
    text-decoration: underline;
  }
}
.status {
  color: #fd416c;
}
.green {
  color: #01d088 !important;
}

.deletePayment {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5%;
  cursor: pointer;
  align-items: center;
  .bin {
    background-image: url("/delete.svg");
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
  }
}
.message-status {
  color: #0b47d0;
  font-family: $font-family-bItalic;
  font-family: 13px;
}
.readMessage {
  color: rgb(1, 208, 136);
}

.edit-offer-icon {
  background-image: url("/chat-actions-icons/edit-offer.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.add-policy {
  background-image: url("/chat-actions-icons/addPolicy.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.photo-message {
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
}

.reply-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  &.reversed {
    align-items: flex-end;
  }

  .reply-message {
    white-space: nowrap;
    background-color: #f5f5f5;
    border: 1px solid gray;
    font-weight: 400;
    font-style: italic;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 7px;
    max-width: 350px;
    text-align: initial;
    overflow-wrap: break-word;
    cursor: pointer;
  }
}
.reply-icon {
  background-image: url("/chat-actions-icons/reply-arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  margin: 0px 5px;
}

.reply-button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  padding:10px;
  cursor: pointer;
  background-color: white;
}
.reply-menu-icon {
  display: inline-block;
  background-image: url("/chat-actions-icons/menu-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
}

@media only screen and (max-width: 900px) {
  .message {
    font-size: 13px;
    .message-body {
      max-width: 300px;
    }
  }
}
