.search-container {
  display: flex;
  align-items: center;
  max-height: 80px;
  padding: 5px 10px;
  .action-buttons {
    display: flex;
    svg {
      color: #0dc1ce;
    }
  }
}
