.system-container {
  padding: 20px;
  .system-heading {
    font-size: 22px;
    margin-top: 20px;
  }
  .system-content {
    margin-top: 50px;
    .filters-container {
      // padding-left: 40px;
      display: flex;
      // width: 60%;
      // justify-content: space-between;
      align-items: center;
      .filter-button {
        padding: 0 10px;
        font-size: 14px;
        text-transform: none;
        color: black;
      }
    }
  }
}

::-webkit-scrollbar {
  scrollbar-width: thin;
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px lightgray;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 2px;
}

@media screen and (max-width: 900px) {
  .system-container{
    padding: 15px;
    .system-content{
      margin-top:25px;
    }
  }
}