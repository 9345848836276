.notes-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 85vh;
  padding:0.5vw;
  .notes-heading-container {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
  .notes-window {
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    // flex-direction: column;
    .notes-history {
      flex-direction: column-reverse;
      height: 95%;
      overflow: auto;
      flex: 1 1 auto;
      display: flex;
      // flex-direction: column;
      align-items: flex-end;
    }
  }
  .notes-form {
    display: flex;
    background-color: #effbfc;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
  }
}
.message {
  width: 100%;
  padding: 12px;
  padding-top: 0;
  // display: flex;
  // align-items: center;
  &:last-child {
    padding-top: 12px;
  }
  &.me {
    text-align: right;
    .message-body {
      background-color: #f5f4f4;
    }
  }
  .time {
    // display: inline-block;
    font-size: 14px;
    padding: 10px;
  }
  .message-body {
    background-color: #f4fafa;
    font-weight: 400;
    display: inline-block;
    padding: 12px;
    border-radius: 10px;
    max-width: 750px;
    min-width: 150px;
    line-height: 20px;
    text-align: initial;
  }
  .media {
    color: #0dc1ce;
    text-decoration: underline;
  }
}
.sendEmails{
  background-size: cover;
  width:50px;
  height: 50px;
  background-image: url("/chat-actions-icons/sendReminder.svg");
  cursor: pointer;
}