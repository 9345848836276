.paper-container {
  height: 88vh;
  margin: 20px 35px;
}

@media only screen and (max-width: 800px) {
  .paper-container {
    margin:10px 10px;
    height: 90vh;
  }
}
