.assign-topic-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 350px;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      svg {
        cursor: pointer;
      }
    }
    .select-agent {
      padding: 15px;
      overflow: auto;
      height: 240px;
      display: flex;
      flex-direction: column;
      .agent {
        display: flex;
        padding: 10px;
        align-items: center;
        border-bottom: 1px solid #0dc1ce;
        p {
          margin-left: 10px;
        }
      }
    }
  }
  .suffix{
    width:30px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left:1px solid #d8d5dd;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}