.assign-topic-modal {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 800px;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
}
.empty-template{
    background-image: url("/chat-actions-icons/empty-template.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 50%;
    width: 50%;
    margin-top:2%;
}
.empty-template-temp{
   background-image: url("/chat-actions-icons/temp-empty.png");
   background-size: contain;
   background-repeat: no-repeat;
   height: 50%;
   width: 60%;
   margin-top:2%;
}
 .template-topics-container{
    width: 100%;
    height: 60px;
    display:flex;
    overflow-x: auto;
    margin-top:1%;
    
 }
 .template-topic{
    font-size:13px;
    padding:7px;
    white-space:nowrap;
    display:flex;
    justify-content: center;
    cursor: pointer;
    width: 30%;
    margin-bottom:-1px;
 }

 .selected{
    border-bottom:3px solid #0DC1CE;
    border-radius: 2px;
 }

 .template-answers-container{
    width:100%;
    padding:15px;
    margin-top:1%;
    height: 85%;
    overflow-y: auto;
 }
 .answer-container{
    width:100%;
    display:flex;
    align-items: center;
    margin-top:3%;
 }
 .answer{
    width:90%;
    min-height: 60%;
    display:flex;
    background-color: #F5F4F4;
    border-radius: 7px;
    align-items: center;
 }

 .copy-icon{
    background-image: url("/chat-actions-icons/copy.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:10%;
    height: 30px;
    margin-left:2%;
    cursor: pointer;
 }
 .copy-ready{
    background-image: url("/chat-actions-icons/copy-ready.svg") !important;
 }
  
 .edit-group{
   background-image: url("/chat-actions-icons/edit-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:12%;
    height: 40px;
    margin-left:2%;
    margin-right: 5%;
    cursor: pointer;
 }
 .delete-group{
   background-image: url("/chat-actions-icons/delete.svg") !important;
 }