.activity-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 85vh;
  padding: 15px;
  .activity-heading-container {
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }
  .activity-window {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .activity {
      margin: 15px 0;
      padding-left: 10px;
      position: relative;
      border-left: 5px solid #0dc1ce;
      .avatar-container {
        display: flex;
        align-items: center;
        margin-top:1%;
        margin-bottom:1%;
      }
    }
    .activity::after {
      content: "";
      position: absolute;
      background-color: black;
      width: 1px;
      height: 30px;
      left: -0.5%;
    }
    .activity:last-of-type::after {
      display: none;
    }
  }
}
.info-icon{
  background-image: url("/chat-actions-icons/info.svg");
  background-size: contain;
  width: 17px;
  height: 17px;
  margin-left:10%;
  cursor: pointer;
  background-repeat: no-repeat;
  &.verified{
      width:30px;
      margin-left:4%;
  }
}
.document-image{
  width: 48%;
  height: 250px;
  margin-top:2%;
  margin-right:2%;
  background-size: contain;
  background-repeat: no-repeat;
}

.old-ticket{
  padding:1vw;
  width:100%;
  box-shadow:0 1px 3px rgba(0, 0, 0, 0.3);
  margin-top:3%;
  cursor: pointer;
}