.assign-topic-modal {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 90vh;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
}
.filter-part{
   padding:1vw;
   width: 100%;
   display: flex;
   flex-direction: column;
}
.filter {
   width: 100%;
   height: 40px;
   box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
   display: flex;
   align-items: center;
   border-radius: 7px;
   margin-top: 3%;
 }
ul{
   padding-top:0px !important;
   padding-bottom: 0px !important;
}

