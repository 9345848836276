@import "../../../base.scss";

.topics-container {
  height: 89vh;
  width: 72%;
  margin: 20px 40px;

  .toolbar {
    display: flex;
    align-items: center;
    padding: 15px;
    h3 {
      padding-right: 20px;
    }
  }
  .topics-list {
    overflow-y: auto;
    height: 82%;
    overflow-x: hidden;
    padding: 15px;
  }
}
.no-topics {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 20px;
  }
}

.datepicker-container{
  height: unset !important;
}
.filters-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.types-selector{
  width:142px;
  height:35px;
  border:none !important;
}
.end-button{
  background-color:"#0DC1CE";
  height:55px;
  border-radius:"4px";
  width:"43px";
  margin-right:"-1%";
  display:"flex";
  align-items:"center";
  justify-content:"center";
}


.button-container{
  width:100%;
  height:40px;
}

.lower-card-container{
  width:100%;
  height:35px;
  border-radius: 4px;
  background-color: rgb(248, 244, 244);
  margin-bottom:2%;
  margin-top:-0.5%;
  z-index:0;
  display: flex;
  justify-content: space-between ;
}
.last-message{
  font-size: 15px;
  white-space: nowrap;
  padding:8px;
  padding-left:10px;
  width: 85%;
  .bold{
    font-family: $font-family-Xbold;
  }
}
.search-btn{
  background-color: "#0DC1CE";
  border-radius: "4px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  margin-top: "2%";
  margin-left: "5%";
}
.clear-filters{
  background-image: url("/clear-filters.svg");
  background-repeat: no-repeat;
  background-position: center;
  width:20px;
  height:25px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.notification-number {
  padding-top: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #0dc1ce;
  color: white;
  border: 1px solid #0dc1ce;
  text-align: center;
 
}
.notification-number-red{
  background-color: #FD416C;
  border: 1px solid #FD416C;
  padding-top: 6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  text-align: center;
}

.MuiMenu-paper {
  transition-duration: 0s !important;
}
.MuiPaper-root {
  transition: none !important;
}

.buttons-container{
  width: 35%;
  display: flex;
}
.filterButton{
  padding:9px 45px;
  border-radius: 5px;
  border:1px solid #b9c2c4;
  cursor: pointer;
  background-color:#0dc1ce;
  color:white;
  &.pink{
    background-color: #FD416C;
    color:white;
  }
  &.white{
    background-color: white;
    color:black;
  }
  margin-left:5%;
}

.filter-icon{
  background-image: url("/filters.svg");
  background-size: contain;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
}
.circle{
  width:10px;
  height: 10px;
  border-radius: 50%;
  background-color:red;
}

@media screen and (max-width: 1500px){
  .search-btn{
    width:30px;
  }
}

@media screen and (max-width: 1450px){
 
}

@media screen and (max-width: 900px){
 .topics-container{
  width: 100%;
  margin-top:5px;
  margin:0px;
  .topics-list{
    height: 98%;
  }
 }
}
