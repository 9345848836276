@import "../../base.scss";

.amarant-container{
    height: 99%;
}
.upper-info-container{
    width:100%;
    height:5%;
    box-shadow: 0 1px 4px rgba(5, 5, 5, 0.2);
    font-size:14px;
    display: flex;
    align-items: center;
    border-radius:5px;
}
.lower-info-container{
    height:94%;
    padding:20px;
    width:100%;
    font-size:14px;
    .buttons-container{
        width:100%;
        margin-top:1%;
        height:6%;
        display: flex;
        .button-container{
            display:flex;
            justify-content: center;
            padding:10px 20px;
            border: 1px solid #0DC1CE;
            border-radius:5px;
            width:12%;
            height:40px;
            color:black;
            cursor: pointer;
            margin-left:1%;
            white-space: nowrap;
            &.active{
                background-color: #0DC1CE;
                color:white;
            }
        }
        .button-container:first-child{
            margin-left:0%;
        }
    }
}
.columns-container{
    width:100%;
    height:5%;
    font-size:12.5px;
    padding:10px;
    margin-top:1%;
    color:#c5c0c0;
    .upper-names{
        display:flex;
        width:100%;
        .name-icon{
            display: flex;
            align-items: center;
            width:8%;
            margin-left:1%;
            white-space: nowrap;
        }
        .name-icon:nth-child(2){
            width:10%;
        }
    }
}
.tabs-container{
    height:85%;
    width:100%;
    font-size:13px;
    padding:10px;
    margin-top:1%;
    .row-container{
        display: flex;
        width: 100%;
        height:10%;
        align-items: center;
        box-shadow: 0 0px 10px rgba(5, 5, 5, 0.1);
        margin-bottom:1%;
        .data{
            display:flex;
            align-items: center;
            width:8%;
            height: 100%;
            margin-left:1%;
        }
        .data:nth-child(2){
            width:10%;
        }
    }
}
.column{
    width:10%;
}
.download{
    background-image: url("/download.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width:25px;
    height:15px;
    margin-left:5%;
    cursor: pointer;
}
.infoIcon{
    background-image: url("/info.svg");
    background-size: contain;
    width:15px;
    height:15px;
    margin-left:5%;
    cursor: pointer;
}
.inactive{
    pointer-events: none;
    opacity: 0.2;
}
.blurred-background{
    display:none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,.4);
}
.propertyModal{
    position: absolute;
    left: 68%;
    width: 29%;
    top: 7%;
    height: 90%;
    background-color: #fff;
    border-radius: 7px;
}
.errorModal{
    position: absolute;
    left: 25%;
    width: 50%;
    top: 15%;
    height: 63%;
    background-color: #fff;
    border-radius: 7px;
}
.headerModal{
    width:95%;
    padding:20px;
    height:5%;
    display:flex;
    justify-content: space-between;
}
.closeModal{
    background-image: url("/exit.svg");
    background-size: contain;
    width:25px;
    height:25px;
    cursor: pointer;
}
.innerBox{
    width:95%;
    height: 70%;
    border:1px solid black;
    border-radius: 5px;
    box-shadow: 0 0px 10px rgba(5, 5, 5, 0.1);
    padding:20px;

}
.copyButton{
    padding:14px 24px;
    width:50%;
    background-color: #01D088;
    color:white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
.bodyModal{
    width:95%;
    margin-left:4%;
    height:92%;
    margin-top:4%;
    border-left: 1px solid black;
}
.modalLine{
    width:95%;
    border-left:5px solid #0DC1CE;
    padding-left:30px;
    border-radius:2px;
    margin-left:-0.5%;
    margin-bottom:6%;
}
.errorLog{
    background-image: url("/error-details.svg");
    background-size: contain;
    width:30px;
    height:30px;
    cursor: pointer;
}
.sortArrow{
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;  
}
.arrowDown{
    background-image: url("/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:15px;
    height: 15px;
    margin-left:6%;
    cursor: pointer;
    .opened{
        transform: rotate(180deg);
    }
}
.info-inner-tab{
    display: none;
    width:100%;
    padding:7px;
    margin-bottom:1%;
    background-color: white;
    border:1px solid #0DC1CE;
    border-top:none;
    transition: visibility 0s, opacity 1s linear;

}
.show{
    display: block;
    visibility: visible;
    opacity: 1;
}
.info-icon{
    background-image: url("/chat-actions-icons/info.svg");
    background-size: contain;
    width: 17px;
    height: 17px;
    margin-left:10%;
    cursor: pointer;
    background-repeat: no-repeat;
    &.verified{
        width:30px;
        margin-left:4%;
    }
}
.activity-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 600px;
    height: 85vh;
    padding: 15px;
    .activity-heading-container {
      display: flex;
      justify-content: space-between;
      svg {
        cursor: pointer;
      }
    }
    .activity-window {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .activity {
        margin: 15px 0;
        padding-left: 10px;
        position: relative;
        border-left: 5px solid #0dc1ce;
        .avatar-container {
          display: flex;
          align-items: center;
          margin-top:1%;
          margin-bottom:1%;
        }
      }
      .activity::after {
        content: "";
        position: absolute;
        background-color: black;
        width: 1px;
        height: 30px;
        left: -0.5%;
      }
      .activity:last-of-type::after {
        display: none;
      }
    }
  }

@media screen and (max-width: 1600px){
   .tabs-container{
    .row-container{
        .data{
            font-size:12px;
        }
    }
   }
 
}

@media screen and (max-width: 1350px){
  
    .tabs-container{
        .row-container{
            .data{
                font-size:11px;
            }
        }
       }
 
   }


@media screen and (max-width: 1300px){
   .lower-info-container{
       .buttons-container{
           .button-container{
               font-size:13px;
               width:13%;
           }
       }
   }
   
}



::-moz-scrollbar-button:decrement,
::-moz-scrollbar-button:increment,
::-webkit-scrollbar-button:decrement,
::-webkit-scrollbar-button:increment {
  width: 0px;
}

::-moz-scrollbar-button, ::-webkit-scrollbar-button {
  width: 0px;
}

.assign-topic-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 450px;
    .assign-topic-heading-container {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      svg {
        cursor: pointer;
      }
    }
    .select-agent {
      padding: 15px;
      overflow: auto;
      height: 240px;
      display: flex;
      flex-direction: column;
      .agent {
        display: flex;
        padding: 10px;
        align-items: center;
        border-bottom: 1px solid #0dc1ce;
        p {
          margin-left: 10px;
        }
      }
    }
  }


  @media screen and (max-width: 900px){
    .upper-info-container{
        height: unset;
        background-color: white;
        flex-direction: column;
        align-items: flex-start;
        padding:1vw;
        p{
            font-size:15px;
            margin-top:2%;
            margin-bottom:2%;
        }
    }
    .helper-cont{
        margin-top:2%;
        margin-bottom:2%;
        align-items: center;
    }
    .activity-modal{
        width:100% !important;
        height:99.5%;
    }
    .activity-heading-container{
        justify-content: flex-start;
    }
    .assign-topic-modal{
        width: 100%;
        height: 100%;
    }
    .assign-topic-heading-container{
        flex-direction: row-reverse;
        justify-content: flex-end !important;
        align-items: center;
    }
    .headerModal{
        justify-content: flex-end;
        flex-direction: row-reverse;
    }
    .closeModal{
        width: 1.3em;
        height: 1.3em;
    }
    .copyButton{
        width: 100%;
    }
    .lower-info-container{
        padding: 12px;
        height: 40%;
        .buttons-container{
            width: 100%;
            height: 25%;
            overflow-x:auto;
            .button-container{
                width:50%;
                margin-right: 1%;
            }
        }
    }
    .mobile-box{
        padding:1vw;
    }
    .innerBox{
        height: unset;
    }
    .errorModal{
        width: 100%;
        height: 100%;
        top:0%;
        left:0%;
    }
  }