.login-container {
  background: url("/login-assets/Login-background.png") repeat 0 0;
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 20px;
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
    width: 95%;
    padding: 20px;
    .login-form {
      padding: 50px;
      height: 100%;
      justify-content: center;
      .heading {
        font-size: 20px;
        font-weight: bold;
        margin: 40px;
        text-align: center;
      }
      .FormControl {
        margin-top: 50px;
      }
      .submit-button {
        margin-top: 50px;
        background-color: #0dc1ce;
        color: white;
      }
      .submit-button:disabled {
        opacity: 0.5;
      }
    }
  }
}
